import React, { useEffect, useState, Fragment } from "react";

const SelectionControls = ({
	title,
	name,
	id,
	inputId,
	disabled,
	type,
	direction,
	onChange,
	value,
	className,
	onBlur,
	defaultChecked,
	inputIconClassName = "",
	directChange
}) => {
	const [checkedInput, setCheckedInput] = useState(false);

	useEffect(() => {
		setCheckedInput(defaultChecked);
	}, [defaultChecked]);

	const handleChange = ev => {
		if (!directChange) {
			setCheckedInput(ev.target.checked);
		}
	};

	return (
		<label
			htmlFor={id}
			className={`${className ? className : "selectionControl"} ${
				type === "switch" ? "switch" : type
			} ${disabled ? "disabled" : " "}  ${direction ? direction : " "}`}
			onClick={e => e.stopPropagation()}>
			<input
				id={id}
				name={name}
				onChange={e => {
					if (onChange) {
						onChange(e);
					}
					handleChange(e);
				}}
				onBlur={onBlur}
				value={value}
				type={type === "switch" ? "checkBox" : type}
				checked={checkedInput}
				disabled={disabled}></input>
			<span className={`input_label ${title ? "textLabel" : "onlyIcon"}`}>
				{type === "switch" ? (
					<span className="switchBool">
						<span className="input_icon"></span>
						<span className="bool">{checkedInput ? "Yes" : "No"}</span>
					</span>
				) : (
					<span
						id={`r_${id}`}
						className={`input_icon ${inputIconClassName}`}></span>
				)}
				{title ? (
					<Fragment>
						<span id={inputId} className="input_title">
							{title}
						</span>
					</Fragment>
				) : null}
			</span>
		</label>
	);
};

//CheckBox Code can manipulated here
const CheckBox = props => SelectionControls(props);

//Radio Button Code can manipulated here
const Radio = props => SelectionControls(props);

//Switch Button Code can manipulated here
const Switch = props => SelectionControls(props);

// export default CheckBox;

export { CheckBox, Radio, Switch };
