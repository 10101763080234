/* eslint-disable no-console */
import { useRouter } from "next/router";
import { useState, Fragment } from "react";
import Image from "next/image";
import { useFormik } from "formik";
import * as yup from "yup";
import LoginInputFields from "./LoginInputFields";
import { HiInformationCircle, HiMail } from "react-icons/hi";
import { FaEye, FaEyeSlash, FaKey } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { CheckBox } from "../reusable/SelectionControls";
import theme from "../../config/theme";
import LoginAPI from "../../apis/login-api";
import { setCookie } from "nookies";
import { useDispatch } from "react-redux";
import { setUser } from "../../actions/authActions";
import { addToast } from "../../actions/toasts";
import Loader from "../../helpers/Loader";
import axios from "axios";
import Axios from "../../config/Axios";

const LoginLayout = ({ fsmRedirect }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [flipAnimation, setFlipAnimation] = useState("");
	const [showErrorText, setShowErrorText] = useState(false);
	const [loginClicked, setLoginClicked] = useState(false);
	const [resetClicked, setResetClicked] = useState(false);
	const [resetAPISuccess, setResetAPISuccess] = useState(false);
	const [showImage, setShowImage] = useState(false);

	const router = useRouter();
	const dispatch = useDispatch();
	const loginApi = new LoginAPI();
	const loginInitialValues = {
		username: "",
		password: "",
		rememberMe: false
	};

	const loginFormik = useFormik({
		initialValues: loginInitialValues,
		validationSchema: yup.object().shape({
			username: yup
				.string()
				.required("Email is Required")
				.email("Username must be a valid Email"),
			password: yup.string().required("Password is Required")
		}),
		onSubmit: values => {
			loginClickHandler(values);
			setLoginClicked(true);
		}
	});
	const resetFormik = useFormik({
		initialValues: { email: "" },
		validationSchema: yup.object().shape({
			email: yup
				.string()
				.required("Email is Required")
				.email("Email must be a valid email address")
		}),
		onSubmit: values => {
			resetClickHandler(values.email);
			setResetClicked(true);
		}
	});
	const resetClickHandler = emailId => {
		Axios.post("/password-management/forgot-password", { emailId })
			.then(response => {
				setShowErrorText(false);
				setResetAPISuccess(true);
				setResetClicked(false);
				dispatch(
					addToast({
						text: "Reset email sent successfully!",
						type: "success"
					})
				);
			})
			.catch(error => {
				console.log(error);
				setResetClicked(false);
				if (error.response.status === 400) {
					setShowErrorText(true);
					dispatch(
						addToast({
							text: "Email does not exist!",
							type: "error"
						})
					);
				} else {
					dispatch(
						addToast({
							text: "Oops! something went wrong",
							type: "error"
						})
					);
				}
			});
	};

	const goBackClickHandler = () => {
		setFlipAnimation("");
		setShowErrorText(false);
		setResetAPISuccess(false);
		loginFormik.resetForm(loginInitialValues);
	};

	const forgotPasswordClickHandler = () => {
		setFlipAnimation("flipped");
		setShowErrorText(false);
		resetFormik.resetForm({ email: "" });
	};

	const loginClickHandler = ({ username, password, rememberMe }) => {
		const basicAuth = `Basic ${window.btoa(`${username}:${password}`)}`;
		loginApi
			.login(basicAuth)
			.then(data => {
				const domain =
					process.env.FSM_DOMAIN === `localhost`
						? process.env.FSM_DOMAIN
						: `.${process.env.FSM_DOMAIN}`;
				const { accessToken, refreshToken, jwt, expiresIn, userContext } = data;
				const options = {
					maxAge: expiresIn,
					path: "/",
					domain
				};
				setCookie(null, "token", accessToken, options);
				setCookie(null, "oauthToken-apikey", accessToken, options);
				setCookie(null, "jwt", jwt, options);
				setCookie(null, "loggedOut", false, { maxAge: -1, path: "/" });
				setCookie(null, "fsmRedirect", false, {
					maxAge: -1,
					path: "/",
					domain
				});
				if (rememberMe) {
					setCookie(null, "refreshToken", refreshToken, options);
				}
				dispatch(setUser(userContext));
				dispatch(
					addToast({
						text: "Login Successful!",
						type: "success"
					})
				);
				setLoginClicked(false);
				axios.defaults.headers.common["access_token"] = `bearer ` + accessToken;
				router.push(fsmRedirect);
			})
			.catch(error => {
				setShowErrorText(true);
				setLoginClicked(false);
				dispatch(
					addToast({
						text: "Invalid Credentials, Login Failed!",
						type: "error"
					})
				);
				console.log(error);
			});
	};

	return (
		<div className="login">
			{/* <div className="background"></div> */}
			<div className="main-container">
				<div className={`login-container`}>
					<div className={`login-form-container ${flipAnimation}`}>
						<div className="front">
							<Image
								src="/login/logo.svg"
								loading="eager"
								width={175}
								height={100}
								layout="fixed"
								alt="azuga_logo"
							/>
							<p className="azTitle azTitle_tertiary azTitle_grey">
								Login to your account
							</p>
							<form onSubmit={loginFormik.handleSubmit}>
								<LoginInputFields
									className="login-input"
									name="username"
									type="email"
									autoComplete
									value={loginFormik.values.username}
									error={loginFormik.errors.username}
									touched={loginFormik.touched.username}
									onChange={event =>
										loginFormik.setFieldValue(
											event.target.name,
											event.target.value
										)
									}
									placeholder="Your E-mail"
									onBlur={event =>
										loginFormik.setFieldTouched(event.target.name, true)
									}
									trailingIcon={<HiMail />}
								/>
								<LoginInputFields
									className="login-input"
									name="password"
									autoComplete
									type={showPassword ? "text" : "password"}
									value={loginFormik.values.password}
									error={loginFormik.errors.password}
									touched={loginFormik.touched.password}
									onChange={event =>
										loginFormik.setFieldValue(
											event.target.name,
											event.target.value
										)
									}
									placeholder="Your Password"
									trailingIcon={<FaKey className="rotate-icon" />}
									onBlur={event =>
										loginFormik.setFieldTouched(event.target.name, true)
									}
									leadingIcon={
										showPassword ? (
											<FaEyeSlash
												className="leading-icon"
												onClick={() => setShowPassword(false)}
											/>
										) : (
											<FaEye
												className="leading-icon"
												onClick={() => setShowPassword(true)}
											/>
										)
									}
								/>
								<div className="optional-action-container">
									<CheckBox
										title="Remember me"
										type="checkbox"
										name="rememberMe"
										inputIconClassName={"small-checkbox"}
										inputId="input-title"
										className="checkbox-remember-me"
										defaultChecked={loginFormik.values.rememberMe}
										onChange={event =>
											loginFormik.setFieldValue(
												event.target.name,
												event.target.checked
											)
										}
									/>
									<p
										className="forgot-password"
										onClick={forgotPasswordClickHandler}>
										Forgot Password?
									</p>
								</div>
								{loginClicked ? (
									<div
										className="login-button"
										style={{ position: "relative" }}>
										Login
										<Loader inline="true" />
									</div>
								) : (
									<button type="submit" className="login-button">
										Login
									</button>
								)}
							</form>
							{showErrorText && (
								<div className="login-error-text">
									<p>
										We're sorry, the user name or password combination is
										inaccurate.Please try again or use the Forgot Password link
										above.
									</p>
								</div>
							)}
						</div>
						<div className="back">
							<button className="go_back" onClick={goBackClickHandler}>
								<IoArrowBack />
								Go Back
							</button>
							<Image
								src="/login/logo.svg"
								width={175}
								height={125}
								layout="fixed"
								alt="azuga_logo"
								className="logo"
							/>
							<p
								className="azTitle azTitle--no-margin-bottom azTitle_tertiary azTitle_grey"
								style={{ color: theme.az_gray_5 }}>
								PASSWORD RECOVERY / RESET
							</p>
							{resetAPISuccess ? (
								<Fragment>
									<p
										className="azTitle azTitle--no-margin azTitle_XSmall azTitle_grey"
										style={{ fontSize: 12, fontWeight: 400 }}>
										We've sent you an email that will allow you to reset your
										password. Please check your email now.
									</p>
									<div className="email-sent">
										<HiInformationCircle />
										<p>
											If you're still having trouble, mail us on{" "}
											<a
												href="mailto:someone@yoursite.com"
												target="_blank"
												rel="noopener noreferrer">
												customercare@azuga.com
											</a>{" "}
											Include your name, company name and contact phone number,
											so we can reach you quickly to provide assistance
										</p>
									</div>
									<button
										type="button"
										className="password-reset"
										onClick={goBackClickHandler}>
										Login Now
									</button>
								</Fragment>
							) : (
								<Fragment>
									<p
										className="azTitle azTitle--no-margin azTitle_XSmall azTitle_grey"
										style={{ fontSize: 12, fontWeight: 400 }}>
										Enter your email address to receive password reset link
									</p>
									<form onSubmit={resetFormik.handleSubmit}>
										<LoginInputFields
											className="login-input"
											name="email"
											type="email"
											value={resetFormik.values.email}
											error={resetFormik.errors.email}
											touched={resetFormik.touched.email}
											onChange={event =>
												resetFormik.setFieldValue(
													event.target.name,
													event.target.value
												)
											}
											placeholder="Enter your Email-Id registered with Azuga"
											onBlur={event =>
												resetFormik.setFieldTouched(event.target.name, true)
											}
											trailingIcon={<HiMail />}
										/>
										{resetClicked ? (
											<div
												className="password-reset"
												style={{ position: "relative" }}>
												Get Password Reset Link
												<Loader inline="true" />
											</div>
										) : (
											<button type="submit" className="password-reset">
												Get Password Reset Link
											</button>
										)}
									</form>
								</Fragment>
							)}
							{showErrorText && (
								<div className="login-error-text">
									<p>
										We're sorry, we can't find that email address in our
										database.
									</p>
								</div>
							)}
						</div>
					</div>
					<div className="login-footer">
						<div className="app-link-container">
							<a
								href="https://itunes.apple.com/us/app/azuga-fleetmobile/id967106058?mt=8&mkt_tok=eyJpIjoiWlRJeE1qZGhZakJpWTJObCIsInQiOiJtY2s0N3FFZVVBRHlpaVJhbml2QnhFMjlPcXROVzV3RDVsS1ltOEZtSTg5SkU4R2IxU1ZPOTFXdWhNSVZkSjRkaER1YXBZOWFxUThxRU01aURWVTVRdz09In0%3D"
								target="_blank"
								style={{ marginRight: 16 }}>
								<img src="/Auth/appstore.png" alt="ios_app" />
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.azuga.smartfleet&hl=en_US&mkt_tok=eyJpIjoiWlRJeE1qZGhZakJpWTJObCIsInQiOiJtY2s0N3FFZVVBRHlpaVJhbml2QnhFMjlPcXROVzV3RDVsS1ltOEZtSTg5SkU4R2IxU1ZPOTFXdWhNSVZkSjRkaER1YXBZOWFxUThxRU01aURWVTVRdz09In0%3D"
								target="_blank">
								<img src="/Auth/playstore.png" alt="android-app" />
							</a>
						</div>
						<div>
							<p className="footer-text">
								© 2021 Azuga |{" "}
								<a href="https://www.azuga.com/privacy-policy" target="_blank">
									Privacy Policy
								</a>
							</p>
						</div>
					</div>
				</div>
				<div className="marketing-container">
					{showImage ? (
						<img src="/Auth/bg.jpg" alt="backgorund" />
					) : (
						<iframe
							src="https://www.azuga.com/fleet-login-promo"
							title="marketing"
							onError={() => {
								setShowImage(true);
							}}></iframe>
					)}
				</div>
			</div>
		</div>
	);
};

export default LoginLayout;
